import React, { useEffect } from 'react';
import "../css/index.css";
import heroVid from "../video/Hero_animation_nina_color.mp4";
const pageStyles = {
   color: "#232129",
   padding: 0,
   fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

// markup
const IndexPage = () => {
   // const heor_opacity_change = (event) => {
   //    let scrollTop = window.pageYOffset + 120;
   //    let portHeight = window.innerHeight;
   //    if (scrollTop > portHeight / 3) {
   //       document.getElementById("heroVid").style.opacity = (portHeight - scrollTop) / (portHeight * 2 / 3);
   //       document.getElementById("nav-bar").style.backgroundColor = "black";
   //       document.getElementById("nav_line").style.opacity = 1 - (portHeight - scrollTop) / (portHeight * 2 / 3);

   //    } else {
   //       document.getElementById("heroVid").style.opacity = 1;
   //       document.getElementById("nav_line").style.opacity = 0;
   //       document.getElementById("nav-bar").style.backgroundColor = "transparent";
   //    }
   // }
   useEffect(() => {
      document.getElementById("heroVid").play();
      return () => { 
      }
   }, []);
   return (
         <main style={pageStyles}>
            {/* video filler is for filling the space where hero animation are, do not remove*/}
            <div className="video_filler"></div>
            <section className="hero">
               <div className="hero-container">
                  <video playsInline autoPlay muted loop id="heroVid" >
                     <source src={heroVid} type="video/mp4" />
                  </video>
               </div>
            </section>
         </main>
   )
}

export default IndexPage
